.App {
  margin: 0;
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  color: white;
}
.MuiFormControl-root *{
    color: #FFF !important;
    border-color: #FFFFFF !important;
}
.MuiInput-underline:before, .MuiInput-underline:after{
    border-bottom-color: #FFF !important;
}
.MuiInputBase-input {
    width: 500px !important;
}
@media(max-width: 769px){
    .MuiInputBase-input {
        width: 250px !important;
    }
}
.MuiFormControl-root {
    margin-bottom: 20px !important;
}
.MuiFormLabel-root.Mui-focused {
    display: none;
}